<!-- 监控总览 -->
<template>
  <div class="video">
    <div class="videoCenter">
      <div class="topline ln2"></div>
      <!-- <div class="update">刷新倒计时:</div> -->
      <div class="oneList" v-show="!isAll">
        <!-- <div class="topline ln2"></div> -->
        <div style="width:100%;height:100%;position: relative;">
          <iframe
            frameborder="0"
            scrolling="no"
            :src="
              `https://open.ys7.com/ezopen/h5/iframe_se?url=${oneVideo.liveAddress}&accessToken=${oneVideo.accessToken}&autoplay=1`
            "
            width="100%"
            height="100%"
            id="ysOpenDevice"
            allowfullscreen
          ></iframe>

          <div class="huchu" @click="getBtns(oneVideo)">
            <img src="../../assets/png/huchu.png" alt/>
          </div>
          <div
            class="newBtnBox"
            v-if="showId == oneVideo.id ? !btnShow : btnShow"
          >
            <div
              class="closeIcon"
              title="关闭"
              @click="getBtns(oneVideo)"
            ></div>
            <div
              class="yuanjiao"
              title="远焦"
              @click="changeVideo(11, oneVideo)"
            ></div>
            <div
              class="jinjiao"
              title="近焦"
              @click="changeVideo(10, oneVideo)"
            ></div>
            <div
              class="suoxiao"
              title="缩小"
              @click="changeVideo(9, oneVideo)"
            ></div>
            <div
              class="fangda"
              title="放大"
              @click="changeVideo(8, oneVideo)"
            ></div>
            <div class="btn-box">
              <div
                class="shang"
                title="上"
                @click="changeVideo(0, oneVideo)"
              ></div>
              <div
                class="xia"
                title="下"
                @click="changeVideo(1, oneVideo)"
              ></div>
              <div
                class="zuo"
                title="左"
                @click="changeVideo(2, oneVideo)"
              ></div>
              <div
                class="you"
                title="右"
                @click="changeVideo(3, oneVideo)"
              ></div>
              <div
                class="zanting"
                title="暂停"
                :class="{ zantingActive: isTrue }"
                @click="changeVideoStop(-1, oneVideo)"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div class="videoList" v-if="isAll">
        <div
          class="listItem"

          v-for="(item, index) in data.list.slice(
            (data.currpage - 1) * data.pagesize,
            data.currpage * data.pagesize
          )"
          @click="listItemClick"
          :key="index"
        >
          <div @click="listItemClick(item)"
               style="    width: 100%;height: 100%;position: absolute;z-index: 999;background: rgba(0,0,0,.1);"></div>
          <iframe
            :src="item.endTime"
            width="100%"
            height="100%"
            id="ysOpenDevice"
            frameborder="0"
            scrolling="no"
            ref="iframe1"
            allowfullscreen
          ></iframe>
        </div>


        <!--          <div class="newBtnBox" v-if="showId == item.id ? !btnShow : btnShow">-->
        <!--            <div class="closeIcon" title="关闭" @click="getBtns(item)"></div>-->
        <!--            <div-->
        <!--              class="yuanjiao"-->
        <!--              title="远焦"-->
        <!--              @click="changeVideo(11, item)"-->
        <!--            ></div>-->
        <!--            <div-->
        <!--              class="jinjiao"-->
        <!--              title="近焦"-->
        <!--              @click="changeVideo(10, item)"-->
        <!--            ></div>-->
        <!--            <div-->
        <!--              class="suoxiao"-->
        <!--              title="缩小"-->
        <!--              @click="changeVideo(9, item)"-->
        <!--            ></div>-->
        <!--            <div-->
        <!--              class="fangda"-->
        <!--              title="放大"-->
        <!--              @click="changeVideo(8, item)"-->
        <!--            ></div>-->
        <!--            <div class="btn-box">-->
        <!--              <div class="shang" title="上" @click="changeVideo(0, item)"></div>-->
        <!--              <div class="xia" title="下" @click="changeVideo(1, item)"></div>-->
        <!--              <div class="zuo" title="左" @click="changeVideo(2, item)"></div>-->
        <!--              <div class="you" title="右" @click="changeVideo(3, item)"></div>-->
        <!--              <div-->
        <!--                class="zanting"-->
        <!--                title="暂停"-->
        <!--                :class="{ zantingActive: isTrue }"-->
        <!--                @click="changeVideoStop(-1, item)"-->
        <!--              ></div>-->
        <!--            </div>-->
        <!--          </div>-->


        <!-- <div class="btnBox" v-if="showId == item.id ? !btnShow : btnShow">
          <div class="iicon" @click="closedBtns(item)">
          <img src="../../assets/png/closed.png" alt />
          </div>
          <div class="jujiao">
          <div class="yuanjiao" @click="changeVideo(11, item)">
            <img src="../../assets/png/yuanjiao.png" alt />
          </div>
          <div class="jinjiao" @click="changeVideo(10, item)">
            <img src="../../assets/png/jinjao.png" alt />
          </div>
          </div>
          <div class="yuan">
          <div class="shang" @click="changeVideo(0, item)">
            <img src="../../assets/png/shang.png" alt />
          </div>
          <div class="xia" @click="changeVideo(1, item)">
            <img src="../../assets/png/xia.png" alt />
          </div>
          <div class="zuo" @click="changeVideo(2, item)">
            <img src="../../assets/png/zuo.png" alt />
          </div>
          <div class="you" @click="changeVideo(3, item)">
            <img src="../../assets/png/you.png" alt />
          </div>
          <div class="zanting" @click="changeVideoStop(-1, item)">
            <img src="../../assets/png/zanting.png" alt />
          </div>
          </div>
          <div class="fangdasuoxiao">
          <div class="fangda" @click="changeVideo(8, item)">
            <img src="../../assets/png/fangda.png" alt />
          </div>
          <div class="suoxiao" @click="changeVideo(9, item)">
            <img src="../../assets/png/suoxiao.png" alt />
          </div>
          </div>
        </div> -->

        <!-- 按钮 -->
        <!--          <div class="huchu" @click="getBtns(item)">-->
        <!--            <img src="../../assets/png/huchu.png" alt/>-->
        <!--          </div>-->
      </div>
    </div>

    <!--      <el-pagination-->
    <!--        v-if="isAll"-->
    <!--        background-->
    <!--        :hide-on-single-page="true"-->
    <!--        :current-page="data.currpage"-->
    <!--        :page-size="data.pagesize"-->
    <!--        layout="prev, pager, next"-->
    <!--        :total="data.list.length"-->
    <!--        @prev-click="nextpage"-->
    <!--        @next-click="nextpage"-->
    <!--        @current-change="nextpage"-->
    <!--      ></el-pagination>-->


    <!-- <el-pagination background layout=" pager" :total="14"></el-pagination> -->
  </div>
  <!--  </div>-->
</template>
<script>
// import elementResizeDetectorMaker from "element-resize-detector"; //监听dom元素宽高变化的插件
// import survebtn from "./surveillancebtn";
import { qdllj } from '../../assets/js/html'
import { login } from '../../api/login'

export default {
  name: 'PointVideos',
  // components: {
  //   survebtn
  // },
  data () {
    return {
      isTrue: false,
      roleId: localStorage.getItem('id'),
      speed: 2,
      oneVideo: {},
      index: 0,
      btnShow: false,
      accessToken: '',
      deviceserial: '',
      selectId: '',
      isAll: true,
      showId: '',
      data: {
        pagesize: 999,
        currpage: 1,
        list: [],
      },
      pointName: '',
      oneList: [],
      valiPage: [],
      valiList: [],
      waterEquipmentList: [],
      page: 1,
      size: 10,
    }
  },
  created () {
    this.getVideo()
  },
  mounted () {

  },
  methods: {
    listItemClick (val) {
      console.log(val)
      this.$router.push({
        name: 'pointVideo',
        params: { id: val.siteId }
      })  // 只能用 name
    },
    // 查询视频资源
    getVideo () {
      // /video/findAllByPage?page=${page}&size=${size}
      this.$get(`/video/findList`)
        .then((res) => {
          this.valiList = res.data.list
          this.valiList.forEach((item) => {
            item.indexId = item.id
            this.accessToken = item.accessToken
            this.deviceserial = item.cameraDeviceNumber
            // `https://open.ys7.com/ezopen/h5/iframe_se?url=${item.videoAddress}&accessToken=${item.accessToken}&autoplay=1`
            item.endTime =
              `https://open.ys7.com/ezopen/h5/iframe?url=${item.videoAddress}&accessToken=${item.accessToken}&autoplay=1`
          })
          this.data.list = this.valiList
        })
    },
    // 视频切换
    getBtns (item) {
      console.log(item.id)
      this.showId == item.id ? (this.showId = '') : (this.showId = item.id)
      // console.log(this.showId)
      // this.btnShow = true;
    },
    // closedBtns (item) {
    //   this.showId = ''
    // },
    changeVideo (direction, item) {
      this.getVideoAbility(direction, item, 1)
    },
    // 视频停止
    changeVideoStop (speed, item) {
      this.isTrue = !this.isTrue
      this.getVideoAbility(0, item, -1)
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .panel-top {
  display: none !important;
}

.active {
  background-color: rgba(102, 156, 249, 0.4);
}

.video {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  .topline {
    width: 100%;
    height: 5px;
    //background: #61a4e4;
    border-radius: 5px;
  }

  .ln2 {
    //background: #61a4e4;
    height: 5px;
    margin-bottom: 10px;
  }

  .bod {
    width: 10px !important;
    height: 10px !important;
    background: #097fe1;
    border-radius: 50%;
    margin-top: 16px;
    margin-right: 10px;
  }

  .list_left {
    width: 240px;
    height: 100%;
    box-sizing: border-box;
    background-color: #fff;
    margin-right: 15px;
    border-radius: 8px;

    .item {
      box-sizing: border-box;

      .top {
        display: flex;
        height: 45px;
        line-height: 45px;
        border-bottom: 1px solid #e9e9e9;
        box-sizing: border-box;
        padding-left: 15px;

        .numberList {
          margin-left: 44%;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #2c2c2c;
        }
      }

      h3 {
        // height: 44px;
        text-align: left;
        cursor: pointer;
        font-size: 16px;
      }

      .listul {
        height: 760px;
        overflow-y: auto;
        padding: 0px 18px 20px 20px;
      }

      ul > li {
        list-style: none;
        text-align: left;
        padding: 10px 0;
        padding-left: 25px;
        border-bottom: 1px solid #e9e9e9;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2c2c2c;
        cursor: pointer;

        &:hover {
          background-color: #fff;
        }
      }
    }
  }

  .videoCenter {
    overflow: auto;
    flex: 1;
    height: 98%;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 1px 21px 0px rgba(38, 46, 45, 0.06);
    opacity: 0.8;
    border-radius: 9px;

    .update {
      width: 100%;
      height: 45px;
      line-height: 45px;
      border-bottom: 1px solid #e9e9e9;
      margin-bottom: 10px;

      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #eb6100;
    }

    .videoList {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding-right: 15px;
      // background-color: aqua;
      align-content: flex-start;
      // justify-content: space-between;
      .listItem {
        width: 49%;
        height: 44%;
        position: relative;
        margin-left: 1%;
        margin-bottom: 1%;
        background: rgba(94, 94, 94, 1);

        .btnBox {
          position: absolute;
          top: 50%;
          right: 0;
          width: 150px;
          height: 80%;
          padding: 26px;
          background: rgba(5, 29, 70, 0.2);
          // opacity: 0.2;
          transform: translateY(-50%);
          border-radius: 8px;

          .iicon {
            position: absolute;
            width: 26px;
            height: 26px;
            top: 0;
            left: 0;
            cursor: pointer;

            img {
              width: 100%;
            }
          }

          .jujiao {
            width: 150px;
            height: 60px;
            margin-top: 8px;
            // background-color: aquamarine;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .yuanjiao {
              width: 30px;
              height: 30px;
              cursor: pointer;

              img {
                width: 100%;
              }
            }

            .jinjiao {
              width: 30px;
              height: 30px;
              cursor: pointer;

              img {
                width: 100%;
              }
            }
          }

          .yuan {
            width: 140px;
            height: 140px;
            //background: url("../../../assets/png/dayuan.png") center no-repeat;
            background-size: cover;
            background: url("../../assets/png/dayuan.png") center no-repeat;
            position: relative;

            .zanting {
              position: absolute;
              width: 49px;
              height: 49px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              cursor: pointer;

              img {
                width: 100%;
              }
            }

            .shang {
              position: absolute;
              cursor: pointer;
              width: 18px;
              height: 18px;
              top: 10px;
              left: 50%;
              transform: translate(-50%, 0);

              img {
                width: 100%;
              }
            }

            .xia {
              position: absolute;
              cursor: pointer;
              width: 18px;
              height: 18px;
              bottom: 10px;
              left: 50%;
              transform: translate(-50%, 0);

              img {
                width: 100%;
              }
            }

            .zuo {
              position: absolute;
              cursor: pointer;
              width: 18px;
              height: 18px;
              top: 50%;
              left: 10px;
              transform: translate(0, -50%);

              img {
                width: 100%;
              }
            }

            .you {
              position: absolute;
              cursor: pointer;
              width: 18px;
              height: 18px;
              top: 50%;
              right: 10px;
              transform: translate(0, -50%);

              img {
                width: 100%;
              }
            }
          }

          .fangdasuoxiao {
            width: 150px;
            height: 60px;
            // background-color: rgb(73, 207, 248);
            display: flex;
            justify-content: space-between;
            align-items: center;

            .fangda {
              width: 30px;
              height: 30px;
              cursor: pointer;

              img {
                width: 30px;
                height: 30px;
              }
            }

            .suoxiao {
              width: 30px;
              height: 30px;
              cursor: pointer;

              img {
                width: 30px;
                height: 30px;
              }
            }
          }
        }

        .huchu {
          position: absolute;
          width: 25px; /*no*/
          height: 25px; /*no*/
          right: 48px; /*no*/
          margin-left: -10px;
          bottom: 11px; /*no*/
          cursor: pointer;
          background: #3d3d3d;

          img {
            width: 17px; /*no*/
            height: 17px; /*no*/
            padding-top: 4px;

          }
        }
      }
    }

    .oneList {
      margin: 1% 1% 0;
      width: 98%;
      height: 96%;
      padding-bottom: 4px;
      background-color: #333;
      position: relative;

      .huchu {
        position: absolute;
        width: 25px; /*no*/
        height: 25px; /*no*/
        right: 48px; /*no*/
        margin-left: -10px;
        bottom: 11px; /*no*/
        cursor: pointer;
        background: #3d3d3d;

        img {
          /*width: 100%;*/
          width: 17px;
          height: 17px;
          text-align: center;
          padding-top: 3px;
        }
      }
    }
  }

  .videoLeft,
  .videoRight {
    width: 285px;
    height: 100%;
    background-color: #ccc;
  }

  .water_equipment {
    width: 100%;

    .equipment_item {
      width: 100%;
      height: 68px;
      padding: 10px;
      background-color: #fff;
      cursor: pointer;

      &:hover {
        background-color: rgb(152, 152, 243);
      }
    }
  }
}

/deep/ .el-pagination {
  margin-top: -70px;

  /deep/ .number,
  .btn-prev,
  .btn-next {
    background: #ffffff !important;
    border: 1px solid #4994fb;
    border-radius: 3px;
  }

  /deep/ .active {
    background: #4994fb !important;
    border-radius: 3px;
    // background-color: #4994FB;
  }
}

/deep/ .el-pagination.is-background .el-pager li  {
  border: 1px solid #4994fb;
}
</style>
